var site = site || {};

(function ($) {
  Drupal.behaviors.tickerTapeFormatterV1 = {
    $window: $(window),
    windowWidth: 0,
    attach: function (context) {
      var self = this;
      var $modules = $('.js-ticker-tape-formatter', context);

      $modules.each(function () {
        var $module = $(this);

        self.windowWidth = self.$window.width();
        self.getDom($module);
        self.setConfig($module);
        self.checkDuplication($module);
        self.setStyling($module);
        self.setListeners($module);
      });
    },
    checkDuplication: function ($module) {
      var self = this;
      var $itemsDupes;
      var $cloneDupes;

      if ($module.config.tickerWidth < self.windowWidth) {
        $itemsDupes = $module.nodes.items.clone();
        $cloneDupes = $module.nodes.clones.clone();
        $itemsDupes.insertAfter($module.nodes.items.last());
        $cloneDupes.insertAfter($module.nodes.clones.last());
        self.setTickerWidth($module);
      } else {
        return false;
      }
      self.checkDuplication($module);
    },
    setTickerWidth: function ($module) {
      $module.config.tickerWidth = 0;
      // $module.nodes.items to be maintained if further cloning needed.
      $('.js-ticker-tape-formatter-ticker-item', $module).each(function () {
        $module.config.tickerWidth += $(this).outerWidth(true);
      });
    },
    setStyling: function ($module) {
      var self = this;
      var css = window.document.styleSheets[0];
      var dirChange = site.direction.isRTL ? '' : '-';

      css.insertRule(
        `
      @keyframes tickertape {
        0% { transform: translate3d(0, 0, 0) }
        100% { transform: translate3d(` +
          dirChange +
          $module.config.tickerWidth +
          `px, 0, 0) }
      }
      `,
        css.cssRules.length
      );

      $module.height($module.nodes.container.outerHeight(true));
      $module.nodes.container.css({
        width: self.windowWidth,
        left: dirChange + $module.offset().left + 'px'
      });
    },
    setListeners: function ($module) {
      var self = this;

      self.$window.resize(function () {
        self.windowWidth = self.$window.width();
        self.setTickerWidth($module);
        self.setStyling($module);
      });
    },
    setConfig: function ($module) {
      $module.config = {};
      $module.config.tickerWidth = 0;
    },
    getDom: function ($module) {
      $module.nodes = {};
      $module.nodes.container = $('.js-ticker-tape-formatter-container', $module);
      $module.nodes.ticker = $('.js-ticker-tape-formatter-ticker', $module);
      $module.nodes.items = $('.js-ticker-tape-formatter-ticker-item', $module.nodes.ticker);
      $module.nodes.clones = $(
        '.js-ticker-tape-formatter-ticker-item--clone',
        $module.nodes.ticker
      );
    }
  };
})(jQuery);
